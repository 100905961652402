import React from 'react';
import { Link } from 'react-router-dom';
import { IMAGE_SERVER } from '../../constants';
import {BooksFilter} from "./interfaces";
import {scrollToTop} from "../../utils";

const Books: React.FC<BooksFilter> = ({ data }) => {

    return (
        <div className={`flex flex-col items-center`}>
            <div className="container md:w-1/2 flex flex-col md:flex-row justify-between items-center">
                <div className="md:flex p-5 border rounded rounded-3x shadow-md bg-white">
                    <Link onClick={() => scrollToTop()}  to={'review-book/' + data.id} className="w-full md:w-1/5 mb-4 md:mb-0 md:mr-4">
                        <img
                            src={IMAGE_SERVER + data.image}
                            alt="Book Cover"
                            className="rounded-md w-24 md:max-w-xs lg:max-w-sm xl:max-w-md"
                        />
                    </Link>

                    <div className="flex flex-col justify-between items-start w-full md:w-3/5">
                        <div>
                            <Link onClick={() => scrollToTop()}  to={'review-book/' + data.id}>
                                <p className="font-bold text-xl cursor-pointer hover:text-indigo-600">
                                    {data.name}
                                </p>
                            </Link>
                            <Link to={`/?author=${data.author.name}`}>
                                <p className="text-md text-custom_gray underline cursor-pointer hover:text-indigo-600">
                                    {data.author.name}
                                </p>
                            </Link>
                            <Link onClick={() => scrollToTop()}  to={'review-book/' + data.id}>
                                <p className="line-clamp-2 h-12 overflow-hidden w-full">
                                    {data.description}
                                </p>
                            </Link>
                        </div>
                        <button className="bg-gray-200 border-gray-300 rounded-md p-2 hover:bg-gray-300 my-2">
                            <Link
                                to={`/posting?bookId=${data.id}`}
                                className="text-custom_gray text-md"
                            >
                                Izoh qoldirish
                            </Link>
                        </button>
                    </div>

                    <div className="flex flex-col justify-between items-end w-full md:w-1/5">
                        <div className="flex">
              <span className="flex items-center justify-center">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 text-indigo-500 mr-1"
                >
                  <path d="M1 8.25a1.25 1.25 0 1 1 2.5 0v7.5a1.25 1.25 0 1 1-2.5 0v-7.5ZM11 3V1.7c0-.268.14-.526.395-.607A2 2 0 0 1 14 3c0 .995-.182 1.948-.514 2.826-.204.54.166 1.174.744 1.174h2.52c1.243 0 2.261 1.01 2.146 2.247a23.864 23.864 0 0 1-1.341 5.974C17.153 16.323 16.072 17 14.9 17h-3.192a3 3 0 0 1-1.341-.317l-2.734-1.366A3 3 0 0 0 6.292 15H5V8h.963c.685 0 1.258-.483 1.612-1.068a4.011 4.011 0 0 1 2.166-1.73c.432-.143.853-.386 1.011-.814.16-.432.248-.9.248-1.388Z" />
                </svg>
                <span className="mr-2 text-black">{data.like_count}</span>
              </span>

                            <span className="flex items-center justify-center">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 text-indigo-500 mr-1"
                >
                  <path d="M18.905 12.75a1.25 1.25 0 1 1-2.5 0v-7.5a1.25 1.25 0 0 1 2.5 0v7.5ZM8.905 17v1.3c0 .268-.14.526-.395.607A2 2 0 0 1 5.905 17c0-.995.182-1.948.514-2.826.204-.54-.166-1.174-.744-1.174h-2.52c-1.243 0-2.261-1.01-2.146-2.247.193-2.08.651-4.082 1.341-5.974C2.752 3.678 3.833 3 5.005 3h3.192a3 3 0 0 1 1.341.317l2.734 1.366A3 3 0 0 0 13.613 5h1.292v7h-.963c-.685 0-1.258.482-1.612 1.068a4.01 4.01 0 0 1-2.166 1.73c-.432.143-.853.386-1.011.814-.16.432-.248.9-.248 1.388Z" />
                </svg>
                <span className="text-black">{data.dislike_count}</span>
              </span>
                        </div>

                        <Link
                            to={`/review-book/${data.id}`}
                            className="text-custom_gray underline text-md hover:text-indigo-600"
                        >
                            Barcha izohlar: {data.review_count}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Books;

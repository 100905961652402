import React, {useRef, useState} from 'react';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../Redux";
import {postFormData} from "../../Server/postRequest/posting";
import {toast, ToastContainer} from "react-toastify";

const Posting = () => {

    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const bookId: number | null = Number(searchParams.get('bookId'));

    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const fileUploadInput = useRef<HTMLInputElement>(null);

    const [title, setTitle] = useState<string>('');
    const [content, setContent] = useState<string>('');

    const userId = useSelector((state: RootState) => state.userInitial.id);
    const token = useSelector((state: RootState) => state.tokenInitial.token);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target?.files?.[0];
        setSelectedImage(file || null);
    };
    const handleSubmit = async (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        try {
            e.preventDefault();
            if (title.length === 0){
                toast("Sarlavha yozilmagan!", {type: "warning"})
                return
            }
            else if (content.length === 0){
                toast("Izoh yozilmagan!", {type: "warning"})
                return
            }

            const formData = new FormData(document.getElementById("add_review") as HTMLFormElement);
            const result = await postFormData('/api/review/save', formData, token);
            if (result.success) {
                navigate('/review-book/' + bookId);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    return (
        <div>
            <ToastContainer position="top-right" />

            {
                userId ?
                    <div className="flex items-center justify-center mt-8">
                        <div className="max-w-2xl bg-white shadow p-4 w-full">
                            <form className="flex flex-col gap-4" id="add_review">
                                <div>
                                    <h5 className="text-lg font-semibold">
                                        Agar sizning sharhingiz bitta ibora bilan cheklangan bo'lsa, nima yozgan bo'lardiz?
                                    </h5>
                                    <input
                                        maxLength={80}
                                        className="w-full border border-gray-300 p-2"
                                        type="text"
                                        id="fname"
                                        name="title"
                                        placeholder="..."
                                        onChange={(e)=> setTitle(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <h5 className="text-lg font-semibold">
                                        Sharhingizni batafsilroq tasvirlash uchun fotosuratni yuklang
                                    </h5>
                                    {selectedImage && (
                                        <div className="mt-2">
                                            <img
                                                alt="not found"
                                                className="w-64"
                                                src={URL.createObjectURL(selectedImage)}
                                            />
                                            <br />
                                            <button
                                                className="text-red-500"
                                                onClick={() => {
                                                    setSelectedImage(null);
                                                }}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    )}
                                    <br />
                                    <input
                                        type="file"
                                        name="image"
                                        ref={fileUploadInput}
                                        onChange={handleImageChange}
                                    />
                                </div>
                                <div>
                                    <h3>To'liq izohingiz:</h3>
                                    <textarea
                                        style={{ height: '400px' }}
                                        maxLength={4000}
                                        className="w-full border border-gray-300 p-2"
                                        name="content"
                                        placeholder="..."
                                        onChange={(e) => setContent(e.target.value)}
                                    />
                                </div>
                                <div className='flex items-center justify-between'>
                                    <div>
                                        <Link to={`/review-book/` + bookId}>
                                            <button
                                                className="border bg-indigo-700 text-white px-2 py-1 rounded-md hover:bg-indigo-900 cursor-pointer">
                                                Ortga qaytish
                                            </button>
                                        </Link>
                                    </div>
                                    <div>
                                        <input type='hidden' name={'book_id'} value={bookId} />
                                        <input className="border bg-indigo-700 text-white px-2 py-1 rounded-md hover:bg-indigo-900 cursor-pointer"
                                               type="submit"
                                               value="Yuklash"
                                               onClick={handleSubmit}
                                        />
                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>

                    :
                    <div>
                        <div className="flex items-center justify-center mt-8 flex-col">
                            <h1 className="posting-warning text-5xl text-center">Iltimos, sharh yozish uchun tizimga kiring!</h1>
                            <br />
                            <Link className="log-in" to="/login">
                                <button className="header-btn bg-indigo-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                    Kirish
                                </button>
                            </Link>
                        </div>
                    </div>
            }
        </div>
    );
};



export default Posting;
import {_apiBase} from "../../constants";
export async function postJsonData(
    url: string = '', data: Record<string, any> = {}, token?: string): Promise<any> {
    try {
        const headers: Record<string, string> = {
            'Content-Type': 'application/json'
        };

        if (token) {
            headers['Authorization'] = token;
        }

        const response = await fetch(_apiBase + url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        });

        return await response.json();
    } catch (error) {
        throw error;
    }
}
export const logIn = async (user: Record<string, any>): Promise<any> => {
    try {
        const response = await postJsonData("/api/login", user);
        return response;
    } catch (error) {
        console.error('Error in logIn:', error);
        throw error;
    }
};

export const registration = async (user: Record<string, any>): Promise<any> => {
    try {
        const response = await postJsonData("/sign-in", user);
        return response;
    } catch (error) {
        console.error('Error in registration:', error);
        throw error;
    }
};


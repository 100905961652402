export const male = "https://www.processindustryforum.com/wp-content/uploads/2018/10/male-placeholder.jpeg";
export const female = 'https://www.goodbeginningscentralvt.org/wp-content/uploads/2017/08/girl-512.png';
export const unknown = 'https://i02.appmifile.com/images/2019/07/27/1f0b9ee0-5117-4dac-89db-bd2972b1c7b4.jpg';
export const IMAGE_SERVER = process.env.REACT_APP_FILES_URL as string | "http://localhost:8000/api/files/";
export const _apiBase = process.env.REACT_APP_BACKEND_URL as string | 'http://localhost:8000';
export const PAGE_SIZE = 12;


export const genres: string[] = [
    "Barcha kitoblar",
    "Badiiy adabiyot",
    "Psixologiya va o'z-o'zini rivojlantirish",
    "Antiutopiya",
    "Biografiya",
    "Biznes haqida kitoblar",
    "IT haqida kitoblar",
    "Bolalar adabiyoti",
    "Boshqalar",
]

export const filterBtn: Record<string, string>[] = [
    {
        title: "Reyting bo'yicha",
        value: 'rating'
    },
    {
        title: "Eng ko'p izoh",
        value: 'reviewed'
    },
    {
        title: "Eng ko'p Like",
        value: 'like_count'
    },
    {
        title: "Eng ko'p Dislike",
        value: 'dislike_count'
    },
]

import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setToken, setUser } from './Redux/rootReducer';
import Login from './components/Login and Registration/Login';
import Registration from './components/Login and Registration/Registration';
import ReviewBook from './components/ReviewBook/ReviewBook';
import Posting from './components/Posting/Posting';
import ReviewComment from './components/ReviewComment/ReviewComment';
import BooksFilter from './components/Books/BooksFilter';
import Layout from './components/Layout/Layout';
import About from "./components/About/About";
import BookSuggestion from "./components/BookSuggestion/BookSuggestion";
import NotFoundPage from "./components/Not Found Image/NotFoundPage";
import Warning from "./Ads/Warning";

const App: React.FC = () => {
    const dispatch = useDispatch();

    const retrieveFromLocalStorage = (key: string) => {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : {};
    };

    useEffect(() => {
        const _user = retrieveFromLocalStorage('user');
        const _token = retrieveFromLocalStorage('token');

        const updateUser = () => dispatch(setUser(_user));
        const updateToken = () => dispatch(setToken(_token));

        if (_user.id && _token.token) {
            updateUser();
            updateToken();
        }
    }, [dispatch]);

    return (

        <div>
            <div>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/registration" element={<Registration />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/book-suggestion" element={<BookSuggestion />} />
                    <Route path="/" element={<Layout />}>
                        <Route index element={<BooksFilter />} />
                        <Route path="/review-book/:id" element={<ReviewBook />} />
                        <Route path="/posting" element={<Posting />} />
                        <Route path="/review-comment/:id" element={<ReviewComment />} />
                    </Route>
                    <Route path='*' element={<NotFoundPage />} />
                </Routes>
            </div>

            <div>
                <Warning />
            </div>
        </div>
    );
};

export default App;

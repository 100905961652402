import React from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Outlet } from 'react-router-dom';

const Layout = () => {
    return (

        <div>
            <div className="min-h-screen flex flex-col justify-between fade-in transition-opacity duration-1000 ease-in-out">
                <Header />
                <Outlet />
                <Footer />
            </div>
        </div>


    );
};

export default Layout;
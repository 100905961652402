import {_apiBase, PAGE_SIZE} from "../../constants";

export const getProductByOrder = async (order_by: string, page: number = 1, pageSize: number = PAGE_SIZE): Promise<any> => {
    const response = await fetch(_apiBase + `/api/books/?page=${page}&page_size=${pageSize}&order_by=${order_by}`);

    if (response) {
        return await response.json();
    }
    return [];
};

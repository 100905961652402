import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {setToken, setUser} from "../../Redux/rootReducer";
import {useNavigate} from "react-router-dom";
import {registration} from "../../Server/postRequest/authentication";
import {toast, ToastContainer} from "react-toastify";

const Registration: React.FC = () => {
    const [name, setName] = useState<string>('');
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [gender, setGender] = useState<string>('');

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const updateUser = (e: any) =>{
        dispatch(setUser(e))
    }
    const updateToken = (e: any) =>{
        dispatch(setToken(e))
    }

    const handleRegistration = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (name.length === 0){
            toast("Ism kiritilmagan!", {type: "warning"})
            return
        }
        else if (login.length === 0){
            toast('Login kiritilmagan!', {type: "warning"})
            return
        }

        else if (login !== login.toLowerCase()){
            toast('Login faqat kichik harflardan iborat bo\'lishi kerak', {type: "warning"})
            return
        }

        else if(password.length < 5){
            toast('Parol kamida 5 ta belgidan iborat bo\'lishi kerak', {type: "warning"})
            return
        }

        else if (password.match(/\d+/) === null){
            toast('Parol kamida bitta raqamni o\'z ichiga olishi kerak', {type: "warning"});
            return
        }

        else if(confirmPassword !== password){
            toast('Parol mos emas!', {type: "warning"});
            return
        }
        else if (gender === ''){
            toast('Jins belgilanmagan!', {type: "warning"});
            return
        }
        e.preventDefault();
        const user = {
            email: login,
            password: password,
            name: name,
            gender: gender,
        };
        try {
            const result = await registration(user);

            if (result.error) {
                console.log(result.error);
            } else {
                console.log("Registration successful");
                updateUser(result.user);
                updateToken(result.token);
                navigate('/');
            }
        } catch (error) {
            toast("Sayt vaqtinchalik ishlamayapti", {type: 'warning'})
        }
    };
    return (
        <div className="min-h-screen flex items-center justify-center">
            <ToastContainer position={"top-right"} />
            <div className="bg-white p-8 shadow-md rounded-md w-96">
                <h2 className="text-2xl font-semibold mb-4">Ro'yxatdan o'tish</h2>
                <form className="space-y-4">
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-600">
                            Ism
                        </label>
                        <input
                            type="text"
                            id="name"
                            className="mt-1 p-2 w-full border rounded-md"
                            placeholder="Ismingizni kiriting"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-600">
                            Login
                        </label>
                        <input
                            type="email"
                            id="email"
                            className="mt-1 p-2 w-full border rounded-md"
                            placeholder="Login kiriting"
                            value={login}
                            onChange={(e) => setLogin(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-600">
                            Parol
                        </label>
                        <input
                            type="password"
                            id="password"
                            className="mt-1 p-2 w-full border rounded-md"
                            placeholder="Parolingizni kiriting"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-600">
                            Parolingizni tasdiqlang
                        </label>
                        <input
                            type="password"
                            id="confirmPassword"
                            className="mt-1 p-2 w-full border rounded-md"
                            placeholder="Parolingizni tasdiqlang"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-600">Jins</label>
                        <div className="mt-2">
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    className="form-radio"
                                    value="male"
                                    checked={gender === 'male'}
                                    onChange={(e) => setGender(e.target.value)}
                                />
                                <span className="ml-2">Erkak</span>
                            </label>
                            <label className="inline-flex items-center ml-6">
                                <input
                                    type="radio"
                                    className="form-radio"
                                    value="female"
                                    checked={gender === 'female'}
                                    onChange={(e) => setGender(e.target.value)}
                                />
                                <span className="ml-2">Ayol</span>
                            </label>
                            <label className="inline-flex items-center ml-6">
                                <input
                                    type="radio"
                                    className="form-radio"
                                    value="incognito"
                                    checked={gender === 'incognito'}
                                    onChange={(e) => setGender(e.target.value)}
                                />
                                <span className="ml-2">Incognito</span>
                            </label>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="w-full bg-indigo-700 text-white p-2 rounded-md hover:bg-indigo-900"
                        onClick={handleRegistration}>
                       Ro'yxatdan o'tish
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Registration;
import {_apiBase, PAGE_SIZE} from "../../constants";

export const getProductByGenre = async (genre: string, page: number = 1, pageSize: number = PAGE_SIZE): Promise<any> => {
    const response = await fetch(_apiBase + `/api/book-by-genre/${genre}?page=${page}&page_size=${pageSize}`);

    if (response) {
        return await response.json();
    }
    return [];
};

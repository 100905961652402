import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const About: React.FC = () => {
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setFadeIn(true);
    }, []);

    return (
        <div className={`flex items-center justify-center min-h-screen bg-gray-100 ${fadeIn ? 'fade-in' : ''}`}>
            <div className="container mx-auto text-center bg-white rounded-lg p-6 shadow-lg w-full md:w-3/5">
                <img
                    src="https://cdn-icons-png.flaticon.com/512/2232/2232688.png"
                    alt="Eureka.uz"
                    className="w-32 h-32 md:w-52 md:h-48 rounded-full mx-auto mb-4 border-2 border-blue-500"
                />
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-4">Eureka.uz</h1>
                <p className="text-lg md:text-xl lg:text-2xl text-gray-800 mb-6">
                    Xush kelibsiz! Siz bu yerda o'ziz yoqtirgan kitobingiz haqida boshqalar qoldirgan izohlarni o'qishingiz
                    va albatta o'ziz ham izoh qoldirishingiz mumkin!
                    <br />   <br />
                    Eureka.uz eslatadiki, sayt haqoratli va boshqa shaxslar
                    qadr-qimmatini kamsituvchi izohlarni e'lon qilmaslik hamda o‘chirib yuborish huquqiga ega
                </p>
                <Link
                    to="/"
                    className="text-blue-600 hover:text-blue-800 hover:underline py-2 px-4 inline-block transition-transform transform hover:scale-105"
                >
                    Ortga qaytish
                </Link>
            </div>
        </div>
    );
};

export default About;

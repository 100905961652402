import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PAGE_SIZE } from '../../constants';
import Books from './Books';
import Pagination from './Pagination';
import Filter from "../Header/Filter";
import {getProductByGenre} from "../../Server/getRequest/getProductByGenre";
import {getProductBySearchText} from "../../Server/getRequest/getProductBySearchText";
import {getListOfProducts} from "../../Server/getRequest/getListOfProducts";
import {getProductByAuthor} from "../../Server/getRequest/getProductByAuthor";
import {ProductsPage} from "./interfaces";
import {loading} from "../../utils";
import {getProductByOrder} from "../../Server/getRequest/getProductByOrder";
import Ads from "../../Ads/Ads";


const BooksFilter: React.FC = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const searchTerm: string | null = searchParams.get('search');
    const genreName: string | null = searchParams.get('genre');
    const bookAuthor: string | null = searchParams.get('author');
    const pageNumber: number = Number(searchParams.get('page')) || 1;
    const orderBy: string | null = searchParams.get('order_by');

    const [productsPage, setProductsPage] = useState<ProductsPage>({
        isLoading: true,
        page: pageNumber,
        total: 0,
        data: [],
    });

    useEffect(() => {
        async function getData(): Promise<ProductsPage> {
            let productsPage: ProductsPage = {
                isLoading: true,
                page: pageNumber,
                total: 0,
                data: [],
            };

            try {
                if (genreName) productsPage = await getProductByGenre(genreName, pageNumber, PAGE_SIZE);
                else if (searchTerm) productsPage = await getProductBySearchText(searchTerm, pageNumber, PAGE_SIZE);
                else if (bookAuthor) productsPage = await getProductByAuthor(bookAuthor, pageNumber, PAGE_SIZE);
                else if (orderBy) productsPage = await getProductByOrder(orderBy, pageNumber, PAGE_SIZE);
                else productsPage = await getListOfProducts(pageNumber, PAGE_SIZE);

                return productsPage;
            } catch (error) {
                console.error('Error fetching data:', error);
                return {
                    isLoading: true,
                    page: 0,
                    total: 0,
                    data: [],
                };
            }
        }

        getData().then((resultProductsPage: ProductsPage) => {
            if (resultProductsPage && resultProductsPage.data) {
                setProductsPage((prevProductsPage) => ({
                    ...prevProductsPage,
                    isLoading: false,
                    total: resultProductsPage.total,
                    data: resultProductsPage.data || [],
                }));
            }
        });
    }, [genreName, searchTerm, bookAuthor, pageNumber, orderBy]);


    if (productsPage.isLoading) {
        return loading();
    }


    return (
        <div>
            <Filter />
            <div>
                {productsPage.data.map((item, index) => {
                    return <Books key={index} data={item} />;
                })}
            </div>

            <Pagination postsPerPage={PAGE_SIZE} totalPosts={productsPage.total}/>
        </div>
    );
};

export default BooksFilter;

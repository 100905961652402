import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {backPage, nextPage, scrollToTop} from "../../utils";
import {PaginationProps} from "./interfaces";


const Pagination: React.FC<PaginationProps> = ({ postsPerPage, totalPosts }) => {
    const pageNumbers: number[] = [];
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const page: number | null = Number(searchParams.get('page')) || 1;
    const genre: string | null = searchParams.get('genre');
    const author: string | null = searchParams.get('author');
    const search: string | null = searchParams.get('search');
    const order_by: string | null = searchParams.get('order_by');

    const totalPages = Math.ceil(totalPosts / postsPerPage);
    const displayPages = 4;

    let startPage = Math.max(1, page - Math.floor(displayPages / 2));
    let endPage = Math.min(totalPages, startPage + displayPages - 1);

    if (endPage - startPage + 1 < displayPages) {
        startPage = Math.max(1, endPage - displayPages + 1);
    }

    return (
        <nav className="w-full flex items-center justify-center mt-8">
            <ul className="flex list-none rounded my-2 items-center justify-center ">
                {page > 1 && (
                    <li
                        className="mx-1"
                        onClick={() => {
                            backPage(page, genre, search, author, navigate, order_by);
                            scrollToTop();
                        }}
                    >
                        <Link to="#" className="px-3 py-2 text-4xl text-black cursor-pointer">
                            &laquo;
                        </Link>
                    </li>
                )}

                {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(
                    (number) => (
                        <li
                            key={number}
                            className={`mx-1 ${number === page ? 'font-bold' : ''}`}
                            onClick={() => {
                                scrollToTop();
                            }}
                        >
                            <Link
                                to={{
                                    search: `?${genre ? `genre=${genre}&` : ''}${
                                        author ? `author=${author}&` : ''
                                    }${search ? `search=${search}&` : ''}${order_by ? `order_by=${order_by}&` : ''}page=${number}`,
                                }}
                                className="px-3 py-2 border border-gray-300 rounded bg-white text-gray-800 cursor-pointer shadow-sm"
                            >
                                {number}
                            </Link>
                        </li>
                    )
                )}

                {page < totalPages && (
                    <li
                        className="mx-1"
                        onClick={() => {
                            nextPage(page, totalPages, search, author, navigate, genre, order_by);
                            scrollToTop();
                        }}
                    >
                        <Link to="#" className="px-3 py-2 text-4xl text-black cursor-pointer">
                            &raquo;
                        </Link>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default Pagination;

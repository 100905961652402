import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import {RootState} from "../../Redux";
import {toast, ToastContainer} from "react-toastify";
import {bookSuggestion} from "../../Server/postRequest/bookSuggestion";

const BookSuggestion: React.FC = () => {
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [fadeIn, setFadeIn] = useState(false);
    const token = useSelector((state: RootState) => state.tokenInitial.token);

    useEffect(() => {
        setFadeIn(true);
    }, []);

    const navigate = useNavigate();

    const handleSubmit = async () => {
        if (!token){
            toast("Kitob qo'shish uchun tizimga kiring!", {type: "error"})
            setTimeout(() => {
                navigate("/login")
            }, 5000)
        }

        else if (title.length === 0){
            toast("Kitob nomi kiritilmagan!", {type: "warning"});
            return;
        }
        else if (author.length === 0){
            toast("Muallif ismi kiritilmagan!", {type: "warning"})
            return
        }


        const data = {
            name: title,
            author: author,
        }


        try {
            let result = await bookSuggestion(data, token);
           if (result.success){
               toast("Jo'natildi, tez orada adminlarimiz kitobni qo'shishadi", {type: "success"})
               setTitle('');
               setAuthor('');
            } else {
                console.error("API call failed:", result.error);
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
        }

    };

    return (
        <div className={`relative min-h-screen ${fadeIn ? 'fade-in' : ''}`}>
            <ToastContainer position={"top-right"} />
            <div className="bg-gray-400"></div>
            <div className="absolute inset-0 flex items-center justify-center">
                <div className="container mx-auto text-center bg-white rounded-lg p-6 shadow-lg w-full md:w-3/5">
                    <h1 className="text-4xl font-extrabold mb-4">Qaysi kitob qo'shilishini xohlaysiz?</h1>

                    <div className="mb-4">
                        <label className="block text-black-800 text-sm font-semibold mb-2">Kitob nomi</label>
                        <input
                            type="text"
                            className="w-full md:w-2/5 p-2 border border-gray-500 rounded-md"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-800 text-sm font-semibold mb-2">Muallif ismi sharifi</label>
                        <input
                            type="text"
                            className="w-full md:w-2/5 p-2 border border-gray-500 rounded-md"
                            value={author}
                            onChange={(e) => setAuthor(e.target.value)}
                        />
                    </div>

                    <Link
                        to="/"
                        className="text-indigo-600 hover:text-indigo-800 hover:underline py-2 px-4 inline-block transition-transform transform hover:scale-105"
                    >
                        Ortga qaytish
                    </Link>
                    <button
                        onClick={handleSubmit}
                        className="bg-indigo-500 text-white py-2 px-4 rounded-md hover:bg-indigo-600 transition-transform transform hover:scale-105 mt-2 md:mt-0"
                    >
                        Yuborish
                    </button>


                </div>
            </div>
        </div>
    );
};

export default BookSuggestion;

import {_apiBase, PAGE_SIZE} from "../../constants";

export const getReviewsByBookId = async (id: number, page: number = 1, pageSize: number = PAGE_SIZE, token?: string): Promise<any> => {
    const headers : Record<string, string> = {};

    if (token) {
        headers['Authorization'] = token;
    }

    const response = await fetch(_apiBase + `/api/review/list/${id}?page=${page}&page_size=${pageSize}`, {
        headers: headers,
        method: 'GET'
    });

    if (response) {
        return await response.json();
    }
    return [];
};

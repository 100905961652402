import React from 'react';
import { female, IMAGE_SERVER, male, unknown } from '../../constants';
import { Link } from 'react-router-dom';
import {convertIsoTimestampToNormalDate} from "../../utils";
import {CommentsTypes} from "./interfaces";


const Comments: React.FC<CommentsTypes> = ({ data }) => {
    return (
        <div className="flex flex-col items-center mt-4 w-full">
            <div className="shadow-2xl bg-white p-4 w-full">
                <div className="flex flex-col md:flex-row justify-between mb-2 border-b-2 border-gray-300 py-1">
          <span className="text-sm mb-2 md:mb-0 md:mr-4">
              {convertIsoTimestampToNormalDate(data.inserted_time)}
          </span>
                    <div className="flex items-center md:ml-auto">
            <span className="flex items-center justify-center">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className={`w-5 h-5 text-indigo-500 mr-1 cursor-not-allowed hover:text-indigo-700 ${data.is_liked ? 'text-indigo-900' : 'text-indigo-500'}`}
              >
                <path d="M1 8.25a1.25 1.25 0 1 1 2.5 0v7.5a1.25 1.25 0 1 1-2.5 0v-7.5ZM11 3V1.7c0-.268.14-.526.395-.607A2 2 0 0 1 14 3c0 .995-.182 1.948-.514 2.826-.204.54.166 1.174.744 1.174h2.52c1.243 0 2.261 1.01 2.146 2.247a23.864 23.864 0 0 1-1.341 5.974C17.153 16.323 16.072 17 14.9 17h-3.192a3 3 0 0 1-1.341-.317l-2.734-1.366A3 3 0 0 0 6.292 15H5V8h.963c.685 0 1.258-.483 1.612-1.068a4.011 4.011 0 0 1 2.166-1.73c.432-.143.853-.386 1.011-.814.16-.432.248-.9.248-1.388Z" />
              </svg>
              <span className="mr-2 text-black">{data.like_count}</span>
            </span>

                        <span className="flex items-center justify-center">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className={`w-5 h-5 text-indigo-500 mr-1 cursor-not-allowed hover:text-indigo-700 ${data.is_liked === false ? 'text-indigo-900' : 'text-indigo-500'}`}
              >
                <path d="M18.905 12.75a1.25 1.25 0 1 1-2.5 0v-7.5a1.25 1.25 0 0 1 2.5 0v7.5ZM8.905 17v1.3c0 .268-.14.526-.395.607A2 2 0 0 1 5.905 17c0-.995.182-1.948.514-2.826.204-.54-.166-1.174-.744-1.174h-2.52c-1.243 0-2.261-1.01-2.146-2.247.193-2.08.651-4.082 1.341-5.974C2.752 3.678 3.833 3 5.005 3h3.192a3 3 0 0 1 1.341.317l2.734 1.366A3 3 0 0 0 13.613 5h1.292v7h-.963c-.685 0-1.258.482-1.612 1.068a4.01 4.01 0 0 1-2.166 1.73c-.432.143-.853.386-1.011.814-.16.432-.248.9-.248 1.388Z" />
              </svg>
              <span className="text-black">{data.dislike_count}</span>
            </span>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row justify-between mb-4">
                    <div className="flex items-center mb-4 md:mb-0">
                        <img
                            className="rounded-full w-12 h-12"
                            src={
                                data.user.gender === 'male'
                                    ? male
                                    : data.user.gender === 'female'
                                        ? female
                                        : unknown
                            }
                            alt="Profile"
                        />
                        <div className="ml-3">
                            <h6 className="text-sm font-medium cursor-pointer">
                                {data.user.name}
                            </h6>
                        </div>
                    </div>
                    {
                        data.file === null ? null
                            :<img
                                className="w-10 md:w-20 object-cover"
                                src={(IMAGE_SERVER + data.file)}
                                alt="Featured"
                            />
                    }

                </div>
                <h2 className="text-lg font-semibold mb-2">{data.title}</h2>
                <p className="max-w-prose max-h-20 overflow-hidden">{data.content}</p>
                <Link to={'/review-comment/' + data.id}>
                    <p className="rounded mt-4 underline text-indigo-800 hover:text-indigo-500">
                        Sharhni to'liq o'qish &gt;
                    </p>
                </Link>
            </div>
        </div>
    );
};

export default Comments;

import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {IMAGE_SERVER} from "../../constants";
import {getProductById} from "../../Server/getRequest/getProductById";
import {createHandleDislike, createHandleLike, loading, scrollToTop} from "../../utils";
import CommentsFilter from "../Comments/CommentsFilter";
import {State} from "./interfaces";
import {useSelector} from "react-redux";
import {RootState} from "../../Redux";
import {ToastContainer} from "react-toastify";


const ReviewBook: React.FC = () => {

    const token = useSelector((state: RootState) => state.tokenInitial.token);
    const { id } = useParams<{ id: string }>();
    const [state, setState] = useState<State>({
        isLoading: true,
        animate: true,
        data: {
            id: 0,
            bookName: "",
            name: "",
            author_id: 0,
            image: "",
            like_count: 0,
            dislike_count: 0,
            review_count: 0,
            description: "",
            is_liked: null,
            author: {
                id: 0,
                link: "",
                name: "",
            },
            genre: {
                id: 0,
                link: "",
                name: "",
            },
        },
    });


    useEffect(() => {
        const getData = async () => {
            try {
                if (id) {
                    const product = await getProductById(Number(id), token);

                    const newState: State = {
                        data: product,
                        isLoading: false,
                        animate: false,
                    };

                    setState(newState);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        getData();
    }, [id]);

    const handleLike = createHandleLike(state, setState, token);
    const handleDislike = createHandleDislike(state, setState, token);

    if (state.isLoading) {
        return loading();
    }
    return (
        <div className="container mx-auto lg:w-1/2 mt-8">
            <ToastContainer position="top-right" />
            <div className="flex flex-col lg:flex-row bg-white shadow-md rounded-xl p-5">
                <div className="lg:flex-shrink-0">
                    <img className="rounded-md w-20 md:w-64 md:max-w-xs lg:max-w-sm xl:max-w-md object-cover"
                         src={IMAGE_SERVER + state.data.image}
                         alt="Book cover"/>
                </div>

                <div className="lg:ml-4 mt-4 lg:mt-0 w-full">
                    <div className="flex items-center justify-between">
                        <h2 className="text-xl font-bold mb-2 cursor-pointer">{state.data.name}</h2>
                        <div className="flex">
                    <span className={`flex items-center justify-center ${state.animate ? 'animate-like' : ''}`}>
                        <svg onClick={() => handleLike(true)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={`w-6 h-6 cursor-pointer  ${state.data.is_liked ? 'text-indigo-900' : 'text-indigo-500'} hover:text-indigo-700 transition-colors duration-300`}>
                            {state.data.is_liked ? (
                                <path d="M1 8.25a1.25 1.25 0 1 1 2.5 0v7.5a1.25 1.25 0 1 1-2.5 0v-7.5ZM11 3V1.7c0-.268.14-.526.395-.607A2 2 0 0 1 14 3c0 .995-.182 1.948-.514 2.826-.204.54.166 1.174.744 1.174h2.52c1.243 0 2.261 1.01 2.146 2.247a23.864 23.864 0 0 1-1.341 5.974C17.153 16.323 16.072 17 14.9 17h-3.192a3 3 0 0 1-1.341-.317l-2.734-1.366A3 3 0 0 0 6.292 15H5V8h.963c.685 0 1.258-.483 1.612-1.068a4.011 4.011 0 0 1 2.166-1.73c.432-.143.853-.386 1.011-.814.16-.432.248-.9.248-1.388Z" />
                            ) : (
                                <path d="M1 8.25a1.25 1.25 0 1 1 2.5 0v7.5a1.25 1.25 0 1 1-2.5 0v-7.5ZM11 3V1.7c0-.268.14-.526.395-.607A2 2 0 0 1 14 3c0 .995-.182 1.948-.514 2.826-.204.54.166 1.174.744 1.174h2.52c1.243 0 2.261 1.01 2.146 2.247a23.864 23.864 0 0 1-1.341 5.974C17.153 16.323 16.072 17 14.9 17h-3.192a3 3 0 0 1-1.341-.317l-2.734-1.366A3 3 0 0 0 6.292 15H5V8h.963c.685 0 1.258-.483 1.612-1.068a4.011 4.011 0 0 1 2.166-1.73c.432-.143.853-.386 1.011-.814.16-.432.248-.9.248-1.388Z" />
                            )}
                        </svg>
                        <span className={`mr-2 text-black`}>{state.data.like_count}</span>
                    </span>

                            <span className={`flex items-center justify-center ${state.animate ? 'animate-like' : ''}`}>
                        <svg onClick={() => handleDislike(false)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={`w-6 h-6 cursor-pointer ${state.data.is_liked=== false ? 'text-indigo-900' : 'text-indigo-500'} hover:text-indigo-700 transition-colors duration-300`}>
                            {state.data.is_liked === false ? (
                                <path d="M18.905 12.75a1.25 1.25 0 1 1-2.5 0v-7.5a1.25 1.25 0 0 1 2.5 0v7.5ZM8.905 17v1.3c0 .268-.14.526-.395.607A2 2 0 0 1 5.905 17c0-.995.182-1.948.514-2.826.204-.54-.166-1.174-.744-1.174h-2.52c-1.243 0-2.261-1.01-2.146-2.247.193-2.08.651-4.082 1.341-5.974C2.752 3.678 3.833 3 5.005 3h3.192a3 3 0 0 1 1.341.317l2.734 1.366A3 3 0 0 0 13.613 5h1.292v7h-.963c-.685 0-1.258.482-1.612 1.068a4.01 4.01 0 0 1-2.166 1.73c-.432.143-.853.386-1.011.814-.16.432-.248.9-.248 1.388Z" />
                            ) : (
                                <path d="M18.905 12.75a1.25 1.25 0 1 1-2.5 0v-7.5a1.25 1.25 0 1 1 2.5 0v7.5ZM8.905 17v1.3c0 .268-.14.526-.395.607A2 2 0 0 1 5.905 17c0-.995.182-1.948.514-2.826.204-.54-.166-1.174-.744-1.174h-2.52c-1.243 0-2.261-1.01-2.146-2.247.193-2.08.651-4.082 1.341-5.974C2.752 3.678 3.833 3 5.005 3h3.192a3 3 0 0 1 1.341.317l2.734 1.366A3 3 0 0 0 13.613 5h1.292v7h-.963c-.685 0-1.258.482-1.612 1.068a4.01 4.01 0 0 1-2.166 1.73c-.432.143-.853.386-1.011.814-.16.432-.248.9-.248 1.388Z" />
                            )}
                        </svg>
                        <span className={`text-black`}>{state.data.dislike_count}</span>
                    </span>
                        </div>
                    </div>

                    <p className="text-gray-700 mb-2">
                        {state.data.description}
                    </p>
                    <Link to={`/?author=${state.data.author.name}`}>
                        <p className="text-black mb-2 font-bold cursor-pointer">Muallif: <span className="font-normal underline hover:text-indigo-500">{state.data.author.name}</span>
                        </p>
                    </Link>

                    <Link to={`/?genre=${state.data.genre.link}`}>
                        <p className="text-black mb-4 font-bold cursor-pointer">Janr: <span className="font-normal underline hover:text-indigo-500">{state.data.genre.name}</span>
                        </p>
                    </Link>

                    <Link to={`/posting?bookId=${state.data.id}`}  >
                        <button onClick={()=> scrollToTop()} className="bg-indigo-700 text-white py-1 px-1 rounded-full w-full mt-14 hover:bg-indigo-600">
                            Izoh yozish
                        </button>
                    </Link>

                </div>
            </div>

            <CommentsFilter />
        </div>


    );
};

export default ReviewBook;
import React, {useEffect, useState} from 'react';
import {female, IMAGE_SERVER, male, unknown} from "../../constants";
import {Link, useParams} from "react-router-dom";
import {getReviewById} from "../../Server/getRequest/getReviewById";
import {
    convertIsoTimestampToNormalDate,
    createHandleDislikeReview,
    createHandleLikeReview,
    loading,
    scrollToTop
} from "../../utils";
import {useSelector} from "react-redux";
import {RootState} from "../../Redux";
import {AppState} from "./interfaces";
import {ToastContainer} from "react-toastify";


const ReviewComment = () => {
    const token = useSelector((state: RootState) => state.tokenInitial.token);
    const { id } = useParams<{ id: string }>();
    const [state, setState] = useState<AppState>({
        isLoading: true,
        animate: false,
        data: {
            id: 0,
            title: '',
            content: '',
            user_id: 0,
            book_id: 0,
            file: "",
            inserted_time: "",
            is_liked: null,
            like_count: 0,
            dislike_count: 0,
            user: {
                id: 0,
                name: '',
                email: '',
                gender: '',
                registered_at: 0,
            }
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id) {
                    const commentReviews = await getReviewById(Number(id), token);
                    setState(() => ({isLoading: false, animate:false, data: commentReviews }));
                }
            } catch (error) {
                console.error('Error fetching reviews:', error);
            }
        };

        fetchData();
    }, []);

    const handleLikeReview = createHandleLikeReview(state, setState, token);
    const handleDislikeReview = createHandleDislikeReview(state, setState, token);

    if (state.isLoading) {
        return loading();
    }


    return (
        <div className="flex flex-col items-center">
            <ToastContainer position="top-right" />
            <div className="m-2 shadow p-5 bg-white w-full md:w-1/2">
                <div className="flex justify-between border-b-2 border-gray-300 mb-5">
                    <span className="mr-2">{convertIsoTimestampToNormalDate(state.data.inserted_time)}</span>
                    <span className="mx-2">
                        <div className="flex">
                            <span className={`flex items-center justify-center ${state.animate ? 'animate-like' : ''}`}>
                                <svg onClick={() => handleLikeReview(true)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={`w-6 h-6 text-indigo-500 mr-1 cursor-pointer hover:text-indigo-700 ${state.data.is_liked ? 'text-indigo-900' : 'text-indigo-500'}`}>
                                    <path d="M1 8.25a1.25 1.25 0 1 1 2.5 0v7.5a1.25 1.25 0 1 1-2.5 0v-7.5ZM11 3V1.7c0-.268.14-.526.395-.607A2 2 0 0 1 14 3c0 .995-.182 1.948-.514 2.826-.204.54.166 1.174.744 1.174h2.52c1.243 0 2.261 1.01 2.146 2.247a23.864 23.864 0 0 1-1.341 5.974C17.153 16.323 16.072 17 14.9 17h-3.192a3 3 0 0 1-1.341-.317l-2.734-1.366A3 3 0 0 0 6.292 15H5V8h.963c.685 0 1.258-.483 1.612-1.068a4.011 4.011 0 0 1 2.166-1.73c.432-.143.853-.386 1.011-.814.16-.432.248-.9.248-1.388Z" />
                                </svg>
                                <span className="mr-2 text-black">{state.data.like_count}</span>
                            </span>
                            <span className={`flex items-center justify-center ${state.animate ? 'animate-like' : ''}`}>
                                <svg onClick={() => handleDislikeReview(false)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={`w-6 h-6 text-indigo-500 mr-1 cursor-pointer hover:text-indigo-700 ${state.data.is_liked === false ? 'text-indigo-900' : 'text-indigo-500'}`}>
                                    <path d="M18.905 12.75a1.25 1.25 0 1 1-2.5 0v-7.5a1.25 1.25 0 0 1 2.5 0v7.5ZM8.905 17v1.3c0 .268-.14.526-.395.607A2 2 0 0 1 5.905 17c0-.995.182-1.948.514-2.826.204-.54-.166-1.174-.744-1.174h-2.52c-1.243 0-2.261-1.01-2.146-2.247.193-2.08.651-4.082 1.341-5.974C2.752 3.678 3.833 3 5.005 3h3.192a3 3 0 0 1 1.341.317l2.734 1.366A3 3 0 0 0 13.613 5h1.292v7h-.963c-.685 0-1.258.482-1.612 1.068a4.01 4.01 0 0 1-2.166 1.73c-.432.143-.853.386-1.011.814-.16.432-.248.9-.248 1.388Z" />
                                </svg>
                                <span className="text-black">{state.data.dislike_count}</span>
                            </span>
                        </div>
                    </span>
                </div>
                <div className="items-center flex flex-col-reverse md:flex-row-reverse">
                    <h6 className="mt-3 font-bold text-xl md:text-2xl">{state.data.user.name}</h6>
                    <img
                        className="rounded-full w-16 h-16 mt-2 md:mt-0"
                        src={
                            state.data.user.gender === 'male'
                                ? male
                                : state.data.user.gender === 'female'
                                    ? female
                                    : unknown
                        }
                        alt="Profile"
                    />
                </div>
                <h2 className="mt-3 font-bold text-3xl md:text-5xl mb-4">{state.data.title}</h2>
                <pre style={{ fontFamily: 'ui-sans-serif, system-ui' }} className="bg-gray-100 p-4 rounded-3xl overflow-y-hidden whitespace-pre-wrap">{state.data.content}</pre>
                {
                    state.data.file === null ? null
                        :
                        <img className="object-cover mt-3 w-full md:w-72" src={IMAGE_SERVER + state.data.file} alt="Review Image" />
                }
                <Link onClick={() => scrollToTop()}  to={"/review-book/" + state.data.book_id} className="block mt-3"><button className="bg-indigo-700 text-white rounded hover:bg-indigo-500 p-1 w-full md:w-32">Ortga qaytish</button></Link>
            </div>
        </div>
    );
};

export default ReviewComment;
import {_apiBase} from "../../constants";

export async function postFormData(url: string = '', formData: FormData, token?: string): Promise<any> {
    const headers: Record<string, string> = {
        // 'Content-Type': 'multipart/form-data'
    };

    if (token) {
        headers['Authorization'] = token;
    }

    const response = await fetch(_apiBase + url, {
        method: 'POST',
        headers: headers,
        body: formData
    });

    return await response.json();
}
import React, {useEffect, useRef, useState} from 'react';
import { genres, filterBtn } from "../../constants";
import { useNavigate } from "react-router-dom";

const Filter: React.FC = () => {

    const [isGenre, setIsGenre] = useState(false);
    const [selectedOptionGenre, setSelectedOptionGenre] = useState<string | null>(null);
    let navigate = useNavigate();

    const [isFilter, setIsFilter] = useState(false);
    const [selectedOptionLikeOrReview, setSelectedOptionLikeOrReview] = useState<string | null>();

    const genreRef = useRef<HTMLDivElement | null>(null);
    const filterRef = useRef<HTMLDivElement | null>(null);

    const toggleDropdownGenre = () => {
        setIsGenre(!isGenre);
        if (isFilter) setIsFilter(false);
    };
    const toggleDropdownFilter = () => {
        setIsFilter(!isFilter);
        if (isGenre) setIsGenre(false);
    };

    const genreClick = (option: string) => {
        if (option === "Barcha kitoblar") {
            setSelectedOptionGenre(option);
            toggleDropdownFilter();
            setIsGenre(false);
            setIsFilter(false);
            navigate("/")
            return
        }
        setSelectedOptionGenre(option);
        setIsGenre(false);
        setIsFilter(false)
        navigate(`?genre=${option}&page=1`);
    };
    const filterRating = (option: Record<string, string>) => {
        setSelectedOptionLikeOrReview(option.title);
        toggleDropdownGenre();
        setIsFilter(false);
        setIsGenre(false);
        navigate(`?order_by=${option.value}&page=1`)
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            genreRef.current &&
            !genreRef.current.contains(event.target as Node) &&
            filterRef.current &&
            !filterRef.current.contains(event.target as Node)
        ) {
            setIsGenre(false);
            setIsFilter(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    return (
        <div className="w-full">
            <div className="container mx-auto text-center md:w-1/2 xl:w-1/2 mt-8 px-5 flex flex-col md:flex-row justify-between items-center mb-4">
                <div className="mb-4 md:mb-0">
                    <h2 className="text-2xl font-bold mb-2 md:mb-0">Kitob sharhlari</h2>
                </div>

                <div className="relative inline-block text-left mb-4 md:mb-0" ref={genreRef}>
                    <button
                        type="button"
                        className="flex bg-white border-2 border-gray-300 focus:border-blue-600 text-xs bg-gradient-to-b hover:from-gray-100 py-2 px-8 rounded-3xl"
                        onClick={toggleDropdownGenre}
                    >
                        Janrlar:
                        <span className="font-bold mx-1">
                            {selectedOptionGenre}
                            <svg
                                className="inline-block -mr-1 h-5 w-5 text-black"
                                viewBox="0 0 10 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </span>
                    </button>

                    {isGenre && (
                        <div className="origin-top-right absolute right-0 mt-16 lg:mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                            <div
                                className="py-1"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="options-menu"
                            >
                                {genres.map((option) => (
                                    <button
                                        key={option}
                                        onClick={() => {
                                            genreClick(option);
                                        }}
                                        className={`block px-4 py-2 text-sm text-gray-700 w-full text-left ${
                                            selectedOptionGenre === option ? 'bg-gray-100' : 'hover:bg-gray-100'
                                        }`}
                                        role="menuitem"
                                    >
                                        {option}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                <div className="relative inline-block text-left" ref={filterRef}>
                    <button
                        type="button"
                        className="flex items-center bg-white border-2 border-gray-300 text-xs focus:border-blue-600 bg-gradient-to-b hover:from-gray-100 py-2 px-8 rounded-3xl"
                        onClick={toggleDropdownFilter}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-6 h-6 ">
                            <path
                                d="M10 3.75a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM17.25 4.5a.75.75 0 0 0 0-1.5h-5.5a.75.75 0 0 0 0 1.5h5.5ZM5 3.75a.75.75 0 0 1-.75.75h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 .75.75ZM4.25 17a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5h1.5ZM17.25 17a.75.75 0 0 0 0-1.5h-5.5a.75.75 0 0 0 0 1.5h5.5ZM9 10a.75.75 0 0 1-.75.75h-5.5a.75.75 0 0 1 0-1.5h5.5A.75.75 0 0 1 9 10ZM17.25 10.75a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5h1.5ZM14 10a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM10 16.25a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z"
                            />
                        </svg>
                        Saralash: <span className="font-bold mx-1">{selectedOptionLikeOrReview}</span>
                        <svg
                            className="inline-block -mr-1 h-5 w-5 text-black"
                            viewBox="0 0 10 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>

                    {isFilter && (
                        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                            <div
                                className="py-1"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="options-menu"
                            >
                                {filterBtn.map((option) => (
                                    <button
                                        key={option.value}
                                        onClick={() => filterRating(option)}
                                        className={`block px-4 py-2 text-sm text-gray-700 w-full text-left ${
                                            selectedOptionLikeOrReview === option.title ? 'bg-gray-100' : 'hover:bg-gray-100'
                                        }`}
                                        role="menuitem"
                                    >
                                        {option.title}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Filter;

import {_apiBase, PAGE_SIZE} from "../../constants";

export const getListOfProducts = async (page: number = 1, pageSize: number = PAGE_SIZE): Promise<any> => {
    const response = await fetch(_apiBase + `/api/books?page=${page}&page_size=${pageSize}`);

    if (response) {
        return await response.json();
    }
    return {};
};

import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {setToken, setUser} from "../../Redux/rootReducer";
import {Link, useNavigate} from "react-router-dom"
import {logIn} from "../../Server/postRequest/authentication";
import {toast, ToastContainer} from "react-toastify";

const Login: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const updateUser = (e: any) =>{
        dispatch(setUser(e))
    }
    const updateToken = (e: any) =>{
        dispatch(setToken(e))
    }

    const handleLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (email.length === 0){
            toast("Login kiritilmagan!", {type: "warning"})
            return
        }
        else if (password.length === 0){
            toast("Parol kiritilmagan", {type: "warning"})
            return
        }
        e.preventDefault();

        const user = {
            email: email,
            password: password,
        };

        try {
            const result = await logIn(user);

            if (result.error) {
                toast("Foydalanuvchi mavjud emas!", {type: "error"});
                setEmail("");
                setPassword("");
            } else {
                updateUser(result.user);
                updateToken(result.token);

                localStorage.setItem('token', JSON.stringify(result.token));
                localStorage.setItem('user', JSON.stringify(result.user));
                navigate('/');
            }
        } catch (error) {
           toast("Sayt vaqtinchalik ishlamayapti", {type: 'warning'})
        }
    };
    return (
        <div className="min-h-screen flex items-center justify-center">
            <ToastContainer position={"top-center"}/>
            <div className="bg-white p-8 shadow-md rounded-md w-96">
                <h2 className="text-2xl font-semibold mb-4">Kirish</h2>
                <form className="space-y-4">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-600">
                            Login
                        </label>
                        <input
                            type="email"
                            id="email"
                            className="mt-1 p-2 w-full border rounded-md"
                            placeholder="Login kiriting"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-600">
                            Parol
                        </label>
                        <input
                            type="password"
                            id="password"
                            className="mt-1 p-2 w-full border rounded-md"
                            placeholder="Parolingizni kiriting"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button
                        type="button"
                        className="w-full bg-indigo-700 text-white p-2 rounded-md hover:bg-indigo-900"
                        onClick={(e) => {handleLogin(e)}}>
                        Kirish
                    </button>
                </form>
                <div className="mt-6 underline text-gray-600 text-sm">
                    <Link to="/registration" className="hover:text-indigo-700 font-semibold cursor-pointer">
                        Ro'yxatdan o'tish
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Login;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import {scrollToTop} from "../../utils";

const Footer = () => {
    return (
        <div className="bg-white mt-14">
            <footer className="page-footer mt-8 mx-auto py-5" style={{ width: "80%" }}>
                <div className="flex flex-col justify-between items-center md:flex-row">
                    <span className="flex items-center">
                        <img src="https://cdn-icons-png.flaticon.com/512/2232/2232688.png" alt="Social Media" className="w-8 mx-2" />
                        <h4 className="text-2xl cursor-pointer" onClick={() => scrollToTop()}>Eureka.uz</h4>
                    </span>
                    <div className="social-icons">
                        <FontAwesomeIcon icon={faTelegram} className="text-indigo-500 mx-2 cursor-pointer text-3xl icon" />
                        <FontAwesomeIcon icon={faTwitter} className="text-indigo-500 mx-2 cursor-pointer text-3xl icon" />
                        <FontAwesomeIcon icon={faInstagram} className="text-indigo-500 mx-2 cursor-pointer text-3xl icon" />
                    </div>
                </div>
                <small className="block text-center mt-5">&copy; Eureka.uz 2020-2024</small>
            </footer>
        </div>
    );
};

export default Footer;

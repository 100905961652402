import React, { useEffect, useState } from 'react';

const Warning: React.FC = () => {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowPopup(true);
        }, 15000);
    }, []);

    const handleClose = () => {
        setShowPopup(false);
    };

    return (
        <>
            {showPopup && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 border border-gray-300 shadow-md animate-fadeIn">
                        <h3 className="text-3xl text-gray-600">Siz istagan kitobni topa olmadingizmi?</h3>
                        <p className={"text-xl mt-4"}>Kitob qo'shish bo'limiga o'tib so'rovnoma yuboring</p>
                        <button className="mt-4 p-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold rounded-md"
                                onClick={handleClose}>
                            Yopish
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default Warning;

import {_apiBase} from "../../constants";

export const getProductById = async (id: number, token?: string): Promise<any> => {
    const headers : Record<string, string> = {};

    if (token) {
        headers['Authorization'] = token;
    }

    const response = await fetch(_apiBase + `/api/book/${id}`, {
        headers: headers,
        method: 'GET'
    });

    if (response) {
        return await response.json();
    }
    return [];
};

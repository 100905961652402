import {postJsonData} from "./authentication";

export const likeOrDislike = async (data: Record<string, any> = {}, token?: string): Promise<any> => {
    const response = await postJsonData("/api/book/rate", data, token)
    return response;
}
export const likeOrDislikeReview = async (data: Record<string, any> = {}, token?: string): Promise<any> => {
    const response = await postJsonData("/api/review/rate", data, token)
    return response;
}



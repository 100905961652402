import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {StateType, Token, User} from "./interfaces";

const initialState: StateType = {
    userInitial: {
        id: 0,
        email: "",
        name: "",
        gender: "",
        registered_at: "",
    },
    tokenInitial: {
        token: '',
    },
};

const itemsSlice = createSlice({
    name: 'state',
    initialState: initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User>) => {
            state.userInitial = action.payload;
        },
        setToken: (state, action: PayloadAction<Token>) => {
            state.tokenInitial = action.payload;
        },
    },
});

export const { setUser, setToken } = itemsSlice.actions;
export const rootReducer = itemsSlice.reducer;

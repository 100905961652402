import React, {useEffect, useState} from 'react';
import {useParams, useSearchParams} from "react-router-dom";
import {getReviewsByBookId} from "../../Server/getRequest/getReviewsByBookId";
import Comments from "./Comments";
import Pagination from "../Books/Pagination";
import {PAGE_SIZE} from "../../constants";
import {loading} from "../../utils";
import {Review} from "./interfaces";
import {useSelector} from "react-redux";
import {RootState} from "../../Redux";

const CommentsFilter = () => {
    const token = useSelector((state: RootState) => state.tokenInitial.token);
    const [searchParams, setSearchParams] = useSearchParams();
    const pageNumber: number = Number(searchParams.get('page')) || 1;
    const { id } = useParams<{ id: string }>();
    const [reviewsState, setReviewsState] = useState<Review>({
        isLoading: true,
        total: 0,
        page: pageNumber,
        data: [],
    });


    useEffect(() => {
        const getReviewsData = async () => {
            try {
                if (id) {
                    const reviewsState = await getReviewsByBookId(Number(id), pageNumber, PAGE_SIZE, token);
                    return reviewsState;
                }

            } catch (error) {
                console.error('Error fetching reviews data:', error);

                return {
                    isLoading: true,
                    page: 0,
                    total: 0,
                    data: [],
                };
            }

        };

        getReviewsData().then((resultReviewPage: Review) => {
            if (resultReviewPage && resultReviewPage.data) {
                setReviewsState((prevProductsPage) => ({
                    ...prevProductsPage,
                    isLoading: false,
                    total: resultReviewPage.total,
                    data: resultReviewPage.data || [],
                }));
            }
        });
    }, [id, pageNumber]);

    if (reviewsState.isLoading) {
        return loading();
    }

    return (

        <div>
            <div>
                {reviewsState.data.map(review => (
                    <Comments key={review.id} data={review} />
                ))}
            </div>
            <Pagination postsPerPage={PAGE_SIZE} totalPosts={reviewsState.total}/>
        </div>
    );
};

export default CommentsFilter;
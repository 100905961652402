import React, {useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../Redux";
import {setToken, setUser} from "../../Redux/rootReducer";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Header: React.FC = () => {

    let userInitial = useSelector((state: RootState) => state.userInitial);
    const [searchKey, setSearchKey] = useState<string>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const updateUser = (e: any) =>{
        dispatch(setUser(e))
    }
    const updateToken = (e: any) =>{
        dispatch(setToken(e))
    }

    const logOut = () => {
        toast('Siz tizimdan chiqdingiz!', {type: "info"})
        localStorage.clear();
        updateUser({});
        updateToken({})
    }
    const location = useLocation();
    return (
        <div>
            <ToastContainer position="top-right"/>
            <div className="w-full bg-white border-b-2" >
                <div className="flex flex-col container lg:w-1/2 lg:ml-1/4 lg:mr-1/4 p-3 lg:flex-row justify-between items-center">
                    <div>
                        <ul className="flex justify-between items-center text-custom_gray mb-3 lg:mb-0">
                            <li className={`mx-3 hover:underline ${location.pathname === '/' ? 'text-indigo-700' : ''}`}>
                                <Link to="/">Bosh sahifa</Link>
                            </li>
                            <li className="mx-3 hover:underline"><Link to="/about">Biz haqimizda</Link></li>
                            <li className="mx-3 hover:underline"><Link to="/book-suggestion">Kitob qo'shish</Link></li>
                        </ul>
                    </div>

                    <div>
                        {
                            userInitial.id?
                                <button onClick={e => {logOut()}
                                } className="bg-indigo-700 hover:bg-indigo-500 text-white font-bold py-1.5 px-3 rounded-3xl mr-2">Chiqish</button>
                                :
                                <span className="flex justify-between lg:flex-row">
                                    <Link to="/login" className="bg-indigo-700 hover:bg-indigo-500 text-white font-bold py-1.5 px-3 rounded-3xl mr-2 mt-3 lg:mt-0">Kirish</Link>
                                    <Link to="/registration" className="bg-indigo-700 hover:bg-indigo-500 text-white font-bold py-1.5 px-3 rounded-3xl mt-3 lg:mt-0">Ro'yxatdan o'tish</Link>
                                </span>
                        }

                    </div>
                </div>
            </div>

            <div className="container w-1/2 ml-1/4 mr-1/4 flex flex-col justify-between items-center my-3 px-5 lg:flex-row">
                <div>
                    <Link to="/" className="flex items-center my-3 lg:my-0">
                        <img src='https://cdn-icons-png.flaticon.com/512/2232/2232688.png' className='w-8'/>
                        <h1 className={"text-4xl cursor-pointer font-bold text-gray-700 hover:text-gray-900"}>eureka.uz</h1>
                    </Link>
                </div>

                <div className="relative">
                    <form action="/" onSubmit={e => {
                        e.preventDefault();
                        navigate(`/?search=` + searchKey);
                    }}>
                        <input   name="search" value={searchKey} onChange={e => {
                            setSearchKey(e.target.value)
                        }}
                                 type="text" placeholder="Izlash..." className="border-2 border-gray-300 rounded-md py-2 px-12 lg:px-24 focus:outline-none focus:border-blue-500 transition-all duration-300" />
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute left-3 top-2 w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                    </form>

                </div>
            </div>
        </div>
    );
};

export default Header;